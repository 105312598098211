<template>
  <BCard id="seccion-content">
    <BModal
      v-model="modalshow"
      title="Producto"
      :hide-footer="true"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      :busy="isBusy"
      :ok-disabled="true"
      img-width="200"
      img-height="200"
    >
      <b-card
        no-body
        class="card-developer-meetup"
      >
        <div class="bg-light-primary rounded-top text-center">
          <b-carousel
            id="carousel-interval"
            controls
            indicators
            :interval="1000"
          >
            <b-carousel-slide
              v-for="(fotos, index) in listFotos"
              :key="index"
            >
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  width="200"
                  height="200"
                  :src="fotos"
                >
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>
        <b-card-body>
          <!-- metting header -->
          <div class="meetup-header d-flex align-items-center">
            <div class="meetup-day">
              <h6 class="mb-0">
                Precio
              </h6>
              <h3 class="mb-0">
                S/. {{ formatNumberWithCommas(itemSelected.precio) }}
              </h3>
            </div>
            <div class="my-auto">
              <b-card-title class="mb-25">
                {{ itemSelected.title }}
              </b-card-title>
              <b-card-text class="mb-0">
                {{ itemSelected.descripcion }}
              </b-card-text>
            </div>
          </div>
          <!--/ metting header -->

          <!-- media -->
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="MapPinIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                {{ itemSelected.place.titulo }} - {{ itemSelected.place.servicio }}
              </h6>
              <small>{{ itemSelected.direccion }} - {{ itemSelected.place.ubigeo }} - Destino {{ itemSelected.destino }}</small>
            </b-media-body>
          </b-media>
        </b-card-body>
      </b-card>
    </BModal>
    <BCardHeader class="header-df">
      <BCol
        cols="12"
        md="12"
        class="pl-0"
      >
        <BMedia vertical-align="center">
          <template #aside>
            <feather-icon
              size="36"
              icon="ShoppingBagIcon"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            Productos
          </span>
          <small class="text-muted">Lista de Productos creados por las empresas.</small>
        </BMedia>
      </BCol>
    </BCardHeader>
    <BCard
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <BRow>
          <!-- Per Page -->
          <BCol
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              id="empresa"
              v-model="empresaSel"
              label="nombreComercial"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="empresas"
              placeholder="Filtre por Empresa"
              @input="checkedEmpresa"
            />
            <!-- <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label> -->
          </BCol>

          <!-- Search -->
          <BCol
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="query"
                class="d-inline-block mr-1"
                placeholder="Buscar producto..."
              />
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="selectADR.text"
                right
                variant="outline-primary"
                class="ml-1"
              >
                <b-dropdown-item
                  v-for="sortOption in searchADR"
                  :key="sortOption.value"
                  @click="selectADR = sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </BCol>
        </BRow>
      </div>

      <BTable
        ref="refPromocionListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              variant="primary"
              class="align-middle"
            />
            <strong class="ml-1">Cargando...</strong>
          </div>
        </template>
        <template #cell(titulo)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :variant="`light-primary`"
              >
                <font-awesome-icon
                  :icon="data.item.icono || 'fa-solid fa-list'"
                />
              </b-avatar>
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.nombre }}
            </span>
            <small
              v-if="data.item.precioUnitario"
              class="text-muted d-block "
            >S/. {{ formatNumberWithCommas(data.item.precioUnitario) }}</small>
          </b-media>
        </template>
        <template #cell(directorio)="data">
          <b-badge
            variant="light-secondary"
            class="mr-50 d-block"
          >
            {{ data.item.directorio.nombreComercial }}
          </b-badge>
          <b-badge
            variant="light-info"
            class="mr-50 d-block mt-50"
          >
            {{ data.item.directorio.tipoServicioTuristico.labelFrontend }}
          </b-badge>
        </template>
        <template #cell(estadoProducto)="data">
          <b-badge
            v-if="data.item.estadoProducto === 'R'"
            pill
            variant="light-warning"
            class="text-capitalize"
          >
            PENDIENTE
          </b-badge>

          <b-badge
            v-if="data.item.estadoProducto === 'A'"
            v-b-tooltip.hover.v-success
            :title="`El ${ moment(String(data.item.publishApprove)).format('DD/MM/YYYY') }`"
            pill
            variant="light-success"
            class="text-capitalize"
          >
            APROBADO
          </b-badge>

          <b-badge
            v-if="data.item.estadoProducto === 'D'"
            v-b-tooltip.hover.v-danger
            :title="`El ${ moment(String(data.item.publishDisapproval)).format('DD/MM/YYYY') }
            Motivo: ${data.item.disapprovalReasons}`"
            pill
            variant="light-danger"
            class="text-capitalize"
          >
            DESAPROBADO
          </b-badge>

          <b-badge
            v-b-tooltip.hover.v-secondary
            :title="`El ${ moment(String(data.item.publishRequest)).format('DD/MM/YYYY') }`"
            variant="light-secondary"
            class="mr-50 d-block"
          >
            REGISTRADO
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(acciones)="data">
          <feather-icon
            v-b-tooltip.hover.v-info
            icon="ZoomInIcon"
            class="cursor-pointer mr-50"
            title="Ver Detalle"
            @click="showFotosModal(data.item)"
          />
          <span
            v-if="data.item.estadoProducto === 'R'"
          >
            <div class="d-inline wl200">
              <feather-icon
                v-b-tooltip.hover.v-success
                icon="CheckIcon"
                class="cursor-pointer mr-50"
                title="Aprobar"
                @click="approve(data.item)"
              />
              <feather-icon
                v-b-tooltip.hover.v-danger
                icon="XIcon"
                title="Desaprobar"
                class="cursor-pointer mr-50"
                @click="dessaapprove(data.item)"
              />
            </div>
          </span>
        </template>
        <template #cell()="data">
          <span class="d-block text-nowrap text-justify">
            {{ data.value }}
          </span>
        </template>
      </BTable>
      <div class="mx-2 mb-2">
        <BRow>
          <BCol
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
          </BCol>
          <!-- Pagination -->
          <BCol
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="page"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </BCol>
        </BRow>
      </div>
    </BCard>
  </BCard>
</template>

<script>
import {
  BAvatar,
  BTable,
  BCol,
  BCard,
  BCardHeader,
  BMedia,
  BRow,
  BFormInput,
  BBadge,
  BPagination,
  BSpinner,
  VBTooltip,
  BCarousel,
  BCarouselSlide,
  BModal,
  BDropdown,
  BDropdownItem,
  /* BImg, */
  BCardBody,
  BCardText,
  BCardTitle,
  BMediaAside,
  BMediaBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch } from '@vue/composition-api'
import vSelect from 'vue-select'
import store from '@/store'
import { useNotify } from '@/helpers/toast'
import config from '@/services/config'
import Vue from 'vue'
import moment from 'moment'
import { capitalizeWords } from '@/helpers/strings'
import formatNumberWithCommas from '@/helpers/number'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCard,
    /* BImg, */
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BPagination,
    BBadge,
    BCol,
    BCardHeader,
    BRow,
    BTable,
    vSelect,
    BFormInput,
    BSpinner,
    BCarousel,
    BCarouselSlide,
    BModal,
    BDropdown,
    BDropdownItem,
  },
  props: {
    idAtractivo: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'titulo',
          label: 'Producto',
        },
        {
          key: 'directorio',
          label: 'Empresa',
          thClass: ['text-center'],
          tdClass: ['text-center'],
        },
        {
          key: 'estadoProducto',
          label: 'Estado',
          tdClass: ['text-center'],
          thClass: ['text-center'],
        },
        {
          key: 'Acciones',
          label: 'Acciones',
          tdClass: ['text-center', 'bg-white'],
          thClass: ['text-center'],
        },
      ],
      sortBy: null,
      pathMultimedia: `${config.pathRepo}${config.contextBO}/api/v1/multimedia`,
    }
  },
  mounted() {
    this.getProducto()
    this.getEmpresas()
  },
  methods: {
    clean() {
      this.items = []
    },
    async showFotosModal(item) {
      const img = item.imagenes.split('***')
      this.listFotos = []
      img.map(foto => this.listFotos.push(
        `${this.pathMultimedia}/byUrl?isthumb=true&url=${foto}`,
      ))
      this.itemSelected = {
        title: item.nombre,
        descripcion: item.descripcion,
        precio: item.precioUnitario,
        fotos: this.listFotos,
        place: {
          titulo: item.directorio.nombreComercial,
          servicio: item.directorio.tipoServicioTuristico.labelFrontend,
          ubigeo: `${capitalizeWords(item.directorio.ubigeoAll.dist)} - ${capitalizeWords(item.directorio.ubigeoAll.prov)}`,
        },
        destino: item.directorio.destino.nombre,
        direccion: item.directorio.direccion,
      }
      this.modalshow = true
    },
    approve(item) {
      Vue.swal({
        title: '¿Está seguro?',
        text: '¿Desea aprobar el producto?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await this.aprobarDesaprobar(item.idProducto, 'A', '')
        }
      })
    },
    dessaapprove(item) {
      Vue.swal({
        title: '¿Está seguro?',
        text: '¿Desea desaprobar el producto?',
        icon: 'info',
        input: 'text',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        inputAttributes: {
          autocapitalize: 'on',
        },
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          await this.aprobarDesaprobar(item.idProducto, 'D', result.value)
        }
      })
    },
    async aprobarDesaprobar(idProducto, estado, reasons) {
      await store
        .dispatch('plataforma/PRODUCTO_UPDATE', {
          idProducto,
          estado,
          reasons,
        })
        .then(response => {
          /* FIND  in the array response.idProducto */
          if (response.success) {
            const index = this.items.findIndex(
              item => item.idProducto === response.result.idProducto,
            )
            if (estado === 'D') {
              this.items.splice(index, 1)
              this.notify('Operación Exitosa', 'Producto desaprobado', 'info')
            } else {
              this.items[index] = response.result
              this.notify('Operación Exitosa', 'Producto aprobado', 'success')
            }
            this.getProducto()
          }
        })
        .catch(() => {
          this.notify(
            'Ha ocurrido un error',
            'Intentelo en otro momento.',
            'danger',
          )
        })
        .finally(() => {})
    },
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const page = ref(1)
    const query = ref('')
    const perPage = ref(12)
    const pageOptions = [10, 25, 50, 100]
    const items = ref([])
    const listFotos = ref([])

    const empresaSel = ref(null)
    const empresas = ref([])

    const totalItems = ref(0)
    const totalPages = ref(0)
    const from = ref(0)
    const to = ref(0)
    const of = ref(0)
    const totalRows = ref(0)
    const isBusy = ref(true)
    const modalshow = ref(false)
    const itemSelected = ref({
      title: '',
      descripcion: '',
      precio: '',
      fotos: [],
      place: { titulo: '', servicio: '' },
      destino: '',
      direccion: '',
    })
    const searchADR = ref([
      {
        text: 'Pendientes',
        value: 'R',
      },
      {
        text: 'Aprobados',
        value: 'A',
      },
      {
        text: 'Desaprobados',
        value: 'D',
      },
    ])
    const selectADR = ref({ text: 'Pendiente', value: 'R' })

    const getEmpresas = async () => {
      await store
        .dispatch('plataforma/EMPRESA_FIND_ALL', {
          query: '',
          page: 1,
          limit: 10000,
          sortBy: 'nombreComercial%7CASC',
          idDestino: -1,
          idTipoDirectorio: -1,
          urlSlugDirectorio: '',
        })
        .then(response => {
          if (response.items) {
            empresas.value = response.items
          }
        })
        .catch(() => {
          notify('Ha ocurrido un error', 'Intentelo en otro momento.', 'danger')
        })
    }

    const getProducto = () => {
      isBusy.value = true
      store
        .dispatch('plataforma/PRODUCTO_FIND_ALL', {
          estadoProducto: selectADR.value.value,
          limit: perPage.value,
          page: page.value,
          query: query.value,
          idEmpresa: empresaSel?.value && empresaSel.value.idDirectorio ? empresaSel.value.idDirectorio : -1,
        })
        .then(response => {
          items.value = response.items
          totalRows.value = response.totalRows
          totalItems.value = response.totalItems
          totalPages.value = response.totalPage
          from.value = perPage.value * (page.value - 1) + (totalRows.value ? 1 : 0)
          to.value = perPage.value * (page.value - 1) + totalRows.value
          of.value = totalItems.value
        })
        .catch(() => {
          notify('Ha ocurrido un error', 'Intentelo en otro momento.', 'danger')
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    const checkedEmpresa = () => {
      getProducto()
    }

    watch([query, page, perPage, selectADR], () => {
      getProducto()
    })
    return {
      page,
      query,
      perPage,
      totalItems,
      totalPages,
      from,
      to,
      of,
      totalRows,
      pageOptions,
      notify,
      getProducto,
      getEmpresas,
      items,
      isBusy,
      modalshow,
      listFotos,
      searchADR,
      selectADR,
      itemSelected,
      empresaSel,
      empresas,
      checkedEmpresa,
      moment,
      formatNumberWithCommas,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.header-df {
  background-color: rgba(34, 41, 47, 0.03) !important;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125) !important;
}

.nav-tabs {
  background: #f6f6f6 !important;
}

.actions-bar {
  background: #f6f6f6 !important;
  justify-content: end;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

#seccion-content.card > .card-body {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#seccion-content {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#empresa{
  width: 100% !important;
}
</style>
