export function capitalizeWords(inputString) {
  return inputString?.toLowerCase().replace(/(?:^|\s)\S/g, a => a.toUpperCase())
}

export function extractAnio(text) {
  if (text.includes('Acumulado')) {
    const partes = text.split(' ')
    const anio = partes.find(part => /^\d{4}$/.test(part))
    return anio
  }
  return text
}

export default { capitalizeWords, extractAnio }
