<template>
  <BCard id="seccion-content">
    <BCardHeader class="header-df">
      <BCol
        cols="12"
        md="12"
        class="pl-0"
      >
        <BMedia vertical-align="center">
          <template #aside>
            <feather-icon
              size="36"
              icon="MessageCircleIcon"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            Reviews
          </span>
          <small class="text-muted">Comentarios de usuarios clasificados de acuerdo a su estado de moderación.</small>
        </BMedia>
      </BCol>
    </BCardHeader>
    <BCard
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <BRow>
          <BCol
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              id="empresa"
              v-model="seccionSel"
              label="text"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionsSeccion"
              placeholder="Sección"
              @input="checkedSeccion"
            />
          </BCol>
          <BCol
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-if="empresaShow"
              id="empresa"
              v-model="empresaSel"
              label="nombreComercial"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="empresas"
              placeholder="Filtre por Empresa"
              @input="checkedEmpresa"
            />
            <v-select
              v-if="atractivoShow"
              id="empresa"
              v-model="atractivoSel"
              label="nombre"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="atractivos"
              placeholder="Filtre por Atractivo"
              @input="checkedAtractivo"
            />
          </BCol>
          <BCol
            cols="12"
            md="5"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="query"
                class="d-inline-block mr-1"
                placeholder="Buscar review..."
              />
              <BDropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="selectADR.text"
                right
                variant="outline-primary"
                class="ml-1"
              >
                <b-dropdown-item
                  v-for="sortOption in searchADR"
                  :key="sortOption.value"
                  @click="selectADR = sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </BDropdown>
            </div>
          </BCol>
        </BRow>
      </div>

      <BTable
        ref="refPromocionListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              variant="primary"
              class="align-middle"
            />
            <strong class="ml-1">Cargando...</strong>
          </div>
        </template>
        <template #cell(tituloPost)="data">
          <b-media vertical-align="center !important">
            <template #aside>
              <b-avatar
                size="28"
                :variant="`light-primary`"
              >
                <font-awesome-icon
                  icon="fa-solid fa-comment"
                />
              </b-avatar>
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.tituloPost }}
            </span>
            <small class="text-muted d-block">
              {{ data.item.review }}
            </small>
          </b-media>
        </template>
        <template #cell(estadoPublicacion)="data">
          <b-badge
            v-if="data.item.estadoPublicacion === 'R'"
            pill
            variant="light-warning"
            class="text-capitalize"
          >
            PENDIENTE
          </b-badge>

          <b-badge
            v-if="data.item.estadoPublicacion === 'A'"
            v-b-tooltip.hover.v-success
            :title="`El ${ moment(String(data.item.publishApprove)).format('DD/MM/YYYY') }`"
            pill
            variant="light-success"
            class="text-capitalize"
          >
            APROBADO
          </b-badge>

          <b-badge
            v-if="data.item.estadoPublicacion === 'D'"
            v-b-tooltip.hover.v-danger
            :title="`El ${ moment(String(data.item.publishDisapproval)).format('DD/MM/YYYY') }
            Motivo: ${data.item.disapprovalReasons}`"
            pill
            variant="light-danger"
            class="text-capitalize"
          >
            DESAPROBADO
          </b-badge>

          <b-badge
            v-b-tooltip.hover.v-secondary
            :title="`El ${ moment(String(data.item.crAt)).format('DD/MM/YYYY') }`"
            variant="light-secondary"
            class="mr-50 d-block"
          >
            REGISTRADO
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(acciones)="data">
          <span
            v-if="data.item.estadoPublicacion === 'R'"
          >
            <div class="d-inline wl200">
              <feather-icon
                v-b-tooltip.hover.v-success
                icon="CheckIcon"
                class="cursor-pointer mr-50"
                title="Aprobar"
                @click="approve(data.item)"
              />
              <feather-icon
                v-b-tooltip.hover.v-danger
                icon="XIcon"
                title="Desaprobar"
                class="cursor-pointer mr-50"
                @click="dessaapprove(data.item)"
              />
            </div>
          </span>
        </template>
        <template #cell()="data">
          <span class="d-block text-nowrap text-justify">
            {{ data.value }}
          </span>
        </template>
      </BTable>
      <div class="mx-2 mb-2">
        <BRow>
          <BCol
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
          </BCol>
          <!-- Pagination -->
          <BCol
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="page"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </BCol>
        </BRow>
      </div>
    </BCard>
  </BCard>
</template>

<script>
import {
  BAvatar,
  BTable,
  BCol,
  BCard,
  BCardHeader,
  BMedia,
  BRow,
  BFormInput,
  BBadge,
  BPagination,
  BSpinner,
  VBTooltip,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch } from '@vue/composition-api'
import vSelect from 'vue-select'
import store from '@/store'
import { useNotify } from '@/helpers/toast'
import Vue from 'vue'
import moment from 'moment'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BAvatar,
    BPagination,
    BBadge,
    BCol,
    BCard,
    BCardHeader,
    BMedia,
    BRow,
    BTable,
    vSelect,
    BFormInput,
    BSpinner,
    BDropdown,
    BDropdownItem,
  },
  props: {
    idAtractivo: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'tituloPost',
          label: 'Review',
        },
        {
          key: 'estadoPublicacion',
          label: 'Estado',
          tdClass: ['text-center'],
          thClass: ['text-center'],
        },
        {
          key: 'Acciones',
          label: 'Acciones',
          tdClass: ['text-center', 'bg-white'],
          thClass: ['text-center'],
        },
      ],
      sortBy: null,
    }
  },
  mounted() {
    this.getReviews()
    this.getEmpresas()
    this.getAtractivos()
  },
  methods: {
    clean() {
      this.items = []
    },
    approve(item) {
      Vue.swal({
        title: '¿Está seguro?',
        text: '¿Desea aprobar el review?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await this.aprobarDesaprobar(item.idReview, 'A', '')
        }
      })
    },
    dessaapprove(item) {
      Vue.swal({
        title: '¿Está seguro?',
        text: '¿Desea desaprobar el review?',
        icon: 'info',
        input: 'text',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        inputAttributes: {
          autocapitalize: 'on',
        },
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          await this.aprobarDesaprobar(item.idReview, 'D', result.value)
        }
      })
    },
    async aprobarDesaprobar(idReview, estado, reasons) {
      await store
        .dispatch('plataforma/REVIEWS_UPDATE', {
          idReview,
          estado,
          reasons,
        })
        .then(response => {
          /* FIND  in the array response.idReview */
          if (response.success) {
            const index = this.items.findIndex(
              item => item.idReview === response.result.idReview,
            )
            if (estado === 'D') {
              this.items.splice(index, 1)
              this.notify('Operación Exitosa', 'Review desaprobado', 'info')
            } else {
              this.items[index] = response.result
              this.notify('Operación Exitosa', 'Review aprobado', 'success')
            }
            this.getReviews()
          }
        })
        .catch(() => {
          this.notify(
            'Ha ocurrido un error',
            'Intentelo en otro momento.',
            'danger',
          )
        })
        .finally(() => {})
    },
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const page = ref(1)
    const query = ref('')
    const perPage = ref(12)
    const pageOptions = [10, 25, 50, 100]
    const items = ref([])

    const totalItems = ref(0)
    const totalPages = ref(0)
    const from = ref(0)
    const to = ref(0)
    const of = ref(0)
    const totalRows = ref(0)
    const isBusy = ref(true)
    const searchADR = ref([
      {
        text: 'Pendientes',
        value: 'R',
      },
      {
        text: 'Aprobados',
        value: 'A',
      },
      {
        text: 'Desaprobados',
        value: 'D',
      },
    ])
    const selectADR = ref({ text: 'Pendiente', value: 'R' })

    const empresaSel = ref(null)
    const empresas = ref([])
    const empresaShow = ref(false)

    const atractivoSel = ref(null)
    const atractivos = ref([])
    const atractivoShow = ref(false)

    const optionsSeccion = ref([
      {
        text: 'Todos',
        value: '',
      },
      {
        text: 'Empresa',
        value: 'DIRECTORIO',
      },
      {
        text: 'Atractivo',
        value: 'ATRACTIVO',
      },
    ])
    const seccionSel = ref({
      text: 'Todos',
      value: '',
    })

    const getEmpresas = async () => {
      await store
        .dispatch('plataforma/EMPRESA_FIND_ALL', {
          query: '',
          page: 1,
          limit: 10000,
          sortBy: 'nombreComercial%7CASC',
          idDestino: -1,
          idTipoDirectorio: -1,
          urlSlugDirectorio: '',
        })
        .then(response => {
          if (response.items) {
            empresas.value = response.items
          }
        })
        .catch(() => {
          notify('Ha ocurrido un error', 'Intentelo en otro momento.', 'danger')
        })
    }
    const getAtractivos = async () => {
      await store
        .dispatch('plataforma/ATRACTIVO_FIND_ALL', {
          query: '',
          page: 1,
          limit: 10000,
          sortBy: 'nombre%7CASC',
          idDestino: -1,
          estadoPublicacion: 'P',
          idClasificacion: -1,
        })
        .then(response => {
          if (response.items) {
            atractivos.value = response.items
          }
        })
        .catch(() => {
          notify('Ha ocurrido un error', 'Intentelo en otro momento.', 'danger')
        })
    }
    const getReviews = () => {
      isBusy.value = true
      let seccionId = -1
      if (seccionSel.value && seccionSel.value.value === '') {
        seccionId = -1
      }
      if (seccionSel.value && seccionSel.value.value === 'ATRACTIVO' && atractivoSel.value) {
        seccionId = atractivoSel.value.idAtractivo
      }
      if (seccionSel.value && seccionSel.value.value === 'DIRECTORIO' && empresaSel.value) {
        seccionId = empresaSel.value.idDirectorio
      }
      store
        .dispatch('plataforma/REVIEWS_FIND_ALL', {
          estadoPublicacion: selectADR.value.value,
          limit: perPage.value,
          page: page.value,
          query: query.value,
          idPost: '-1',
          seccion: seccionSel.value ? seccionSel.value.value : '',
          seccionId,
        })
        .then(response => {
          items.value = response.items
          totalRows.value = response.totalRows
          totalItems.value = response.totalItems
          totalPages.value = response.totalPage
          from.value = perPage.value * (page.value - 1) + (totalRows.value ? 1 : 0)
          to.value = perPage.value * (page.value - 1) + totalRows.value
          of.value = totalItems.value
        })
        .catch(() => {
          notify('Ha ocurrido un error', 'Intentelo en otro momento.', 'danger')
        })
        .finally(() => {
          isBusy.value = false
        })
    }
    const checkedSeccion = () => {
      if (seccionSel.value && seccionSel.value.value === 'DIRECTORIO') {
        empresaShow.value = true
        atractivoShow.value = false
      }
      if (seccionSel.value && seccionSel.value.value === 'ATRACTIVO') {
        empresaShow.value = false
        atractivoShow.value = true
      }
      if (!seccionSel.value || seccionSel.value?.value === '') {
        empresaShow.value = false
        atractivoShow.value = false
      }
      getReviews()
    }
    const checkedEmpresa = () => {
      getReviews()
    }
    const checkedAtractivo = () => {
      getReviews()
    }
    watch([query, page, perPage, selectADR], () => {
      getReviews()
    })
    return {
      page,
      query,
      perPage,
      totalItems,
      totalPages,
      from,
      to,
      of,
      totalRows,
      pageOptions,
      notify,
      getReviews,
      items,
      isBusy,
      searchADR,
      selectADR,
      empresaSel,
      empresas,
      atractivoSel,
      atractivos,
      getEmpresas,
      getAtractivos,
      checkedEmpresa,
      checkedAtractivo,
      optionsSeccion,
      seccionSel,
      empresaShow,
      atractivoShow,
      checkedSeccion,
      moment,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.header-df {
  background-color: rgba(34, 41, 47, 0.03) !important;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125) !important;
}

.nav-tabs {
  background: #f6f6f6 !important;
}

.actions-bar {
  background: #f6f6f6 !important;
  justify-content: end;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

#seccion-content.card > .card-body {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#seccion-content {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
